<template>
  <div class="tabs">
    <div class="item" :class="{'active': type_id===item.type_id}" type="primary" @click="typeChange(item)" v-for="item in typeList" :key="item.type_id">{{ item.type_name }}</div>
  </div>
</template>

<script>
import {serviceTypeAll, serviceTypeByUser} from "@/api/request/service";

export default {
  name:'ServiceTypeScroll',
  props:{
    list:{
      type: Array,
      default(){
        return []
      }
    },
    isHttp:{
      type:Boolean,
      default(){
        return false
      }
    },
    user:{
      type:Object,
      default(){
        return {
          user_id: 0,
          user_type: 0,
        }
      }
    }
  },
  data(){
    return {
      typeList:[],
      type_id: 0
    }
  },
  watch: {
    list(newVal) {
      this.typeList = newVal;
      if (this.typeList.length > 0) {
        this.typeChange(this.typeList[0]);
      }
    }
  },
  created() {
    if (this.isHttp) {
      if (this.user.user_type) {
        this.getServiceTypeByUser();
      } else {
        this.getServiceType();
      }
    }
  },
  methods: {
    async getServiceType() {
      const res = await serviceTypeAll({})
      if (res.code!==200) this.$message.error(res.msg);
      this.typeList = res.data;
      if (this.typeList.length > 0) {
        this.typeChange(this.typeList[0]);
      }
    },
    async getServiceTypeByUser() {
      const res = await serviceTypeByUser({uid: this.user.user_id,user_type: this.user.user_type})
      if (res.code!==200) this.$message.error(res.msg);
      this.typeList = res.data;
      if (this.typeList.length > 0) {
        this.typeChange(this.typeList[0]);
      }
    },
    typeChange(e) {
      this.type_id = e.type_id;
      this.$emit('handleServiceTypeChange', this.type_id)
    }
  },
}
</script>

<style lang="scss" scoped>
.tabs{
  display: flex;
  margin-bottom: 15px;
  max-width: 100%;
  overflow-x: scroll;
  //scrollbar-width: none;
  -ms-overflow-style: none;
  .tabs::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .item{
    margin-right: 30px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 1px solid #ddd;
    color: #999;
    //border-radius: 7px;
    cursor: pointer;
    //padding: 0 12px;
    font-size: 16px;
    white-space: nowrap;
    &.active{
      border-bottom: 2px solid #409EFF;
      color: #409EFF;
    }
  }
}
</style>